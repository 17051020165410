<template>
  <!-- 中间内容 -->
  <div class="contenter">
    <div class="aside" style="border: 1px solid #e1e1e1;" :style="'top: ' + stickyHeight">
      <el-menu default-active="key2">
        <el-menu-item v-for="(item, index) in asideList" :key="index" :index="item.index"
                      @click="liClick(item.index)">
          <i :class="item.icon"></i>
          <span slot="title" style="font-weight: bold">
            {{
              item.serviceTitle
            }}
          </span>
        </el-menu-item>
      </el-menu>
    </div>

    <!-- 右侧表单 -->
    <div class="mains" style="margin-left: 20px">
      <!-- <div class="module"> -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
               label-position="left" :disabled="disableForm">
        <!-- 服务信息 -->
        <el-row class="cards" id="key2">
          <el-row style="font-weight: bold; font-size: 14px; color: #606266">服务信息：</el-row>
          <el-row :gutter="20" class="cows">
            <!-- 服务平台 -->
            <el-col :span="8">
              <el-form-item label="服务平台：" class="bolds_padd" prop="platformId">
                <!-- <el-popover :disabled="disableForm" placement="bottom" style="box-shadow: 0 0 4px 2px #e1e1e1"> -->
                <el-popover :disabled="disableForm" placement="bottom">
                  <el-tabs v-model="selectedServicePlatformGroup" tab-position="left" @tab-click="clickServicePlatformTab">
                    <el-tab-pane v-for="(item, index) in labeList" :key="index" :label="item.label"
                                 :name="item.name">
                      <div style="display: flex; width: 400px; flex-wrap: wrap">
                        <el-tag style="
                              margin-bottom: 10px;
                              margin-right: 7px;
                              cursor: pointer;
                            " v-for="(item, index) in renderedServicePlatformList" :key="index"
                                :effect="ruleForm.platformId === item.id ? 'dark' : 'plain'" @click="clickServicePlatform(item, index)">
                          {{ item.platformName }}
                        </el-tag>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                  <el-input placeholder="请选择服务平台" v-model="platform" suffix-icon="el-icon-arrow-down" clearable
                            slot="reference" @clear="ruleForm.platformId = null"></el-input>
                </el-popover>
                <!-- <Tabs></Tabs> -->
              </el-form-item>
            </el-col>
            <!-- 服务类目 -->
            <el-col :span="8">
              <el-form-item label="服务类目：" class="bolds_padd" prop="categoriesId">
                <!-- <el-popover :disabled="disableForm" placement="bottom" style="box-shadow: 0 0 4px 2px #e1e1e1"> -->
                <el-popover :disabled="disableForm" placement="bottom">
                  <div style="
                      display: flex;
                      width: 310px;
                      flex-wrap: wrap;
                      justify-content: space-between;
                    ">
                    <el-tag style="margin-bottom: 10px; cursor: pointer" v-for="(item, index) in serviceCategories"
                            :key="index" :effect="ruleForm.categoriesId === item.value ? 'dark' : 'plain'"
                            @click="clickServiceCategory(item, index)">{{ item.label }}
                    </el-tag>
                  </div>
                  <el-input placeholder="请选择服务类目" v-model="categories" suffix-icon="el-icon-arrow-down"
                            clearable slot="reference" @clear="ruleForm.categoriesId = null"></el-input>
                </el-popover>
              </el-form-item>
            </el-col>
            <!-- 服务项目 -->
            <el-col :span="8">
              <el-form-item label="服务内容：" class="bolds_padd" prop="itemsId">
                <el-popover :disabled="disableForm" placement="bottom">
                  <el-tabs tab-position="left" v-model="serviceItemParentName">
                    <el-tab-pane v-for="(parentItem, index) in serviceItemsList" :key="index"
                                 :label="parentItem.itemsName" :name="parentItem.itemsName">
                      <div style="display: flex; width: 400px; flex-wrap: wrap">
                        <el-tag style="margin: 0 10px 10px 0; cursor: pointer"
                                v-for="(item, index) in parentItem.children" :key="index"
                                :effect="ruleForm.itemsId === item.id ? 'dark' : 'plain'"
                                @click="clickServiceItem(item, index, parentItem)">{{ item.itemsName }}
                        </el-tag>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                  <el-input placeholder="请选择服务项目" v-model="items" suffix-icon="el-icon-arrow-down" clearable
                            slot="reference" @clear="ruleForm.itemsId = null"></el-input>
                </el-popover>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="show">
              <el-form-item label="提供账号：" style="font-weight: bold; padding: 0 20px" prop="serviceAccount">
                <el-radio-group v-model="ruleForm.serviceAccount">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- 服务标签 -->
            <el-col :span="24">
              <el-form-item label="服务标签：" style="font-weight: bold; padding: 0 20px" prop="serviceLabel">
                <el-checkbox-group v-model="checkTagList" @change="handleCheckedCitiesChange" :max="3">
                  <el-checkbox v-for="(item, index) in serviceLabels" :key="index" :label="item.label" border
                               style="margin-right: 0px !important"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <!-- 服务信息end -->
        <!-- 标题主图 -->
        <el-row class="cards" id="key1">
          <el-form-item label="标题：" class="bolds" prop="serviceTitle">
            <el-input v-model="ruleForm.serviceTitle" type="text" placeholder="请输入标题" maxlength="50"
                      show-word-limit></el-input>
          </el-form-item>
          <!-- 主图 -->
          <el-form-item label="主图：" style="position: relative" prop="serviceVideo" class="picuplod bolds">
            <draggable v-model="uploadlist" :item-class="'item'" :clone-class="'clone'" :drag-class="'drag'"
                       :tag="'ul'" class="heihei">
              <template v-for="(item, index) in uploadlist">
                <li :key="index" :class="itemClass(item)">
                  <image-upload class="pic" v-model="item.img" :fileType="fileType" :limit="1" :isShowTip="false"/>
                </li>
              </template>
            </draggable>
          </el-form-item>
          <el-form-item class="bolds" prop="serviceVideo">
            <el-upload ref="video" class="upload-demo" drag action="/admin-api/infra/file/upload" :multiple="false"
                       :headers="{Authorization: 'Bearer ' + getAccessToken()}"
                       :before-upload="beforeUploadVideo"  :before-remove="handleRemove"
                       :on-success="uploadVideoSuccess"
                        :on-exceed="UploadExceed" :limit="1" :file-list="fileList">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将视频文件拖到此处，或
                <em>点击</em>
              </div>
            </el-upload>
            <div class="el-upload__tip">
              请上传 大小不超过
              <b style="color: #f56c6c">50MB</b> 格式为
              <b style="color: #f56c6c">mp4/ogg/flv/avi/wmv/rmvb</b>
              的文件
            </div>
            <!--  进度条 -->
            <el-progress v-show="progressFlag" :percentage="loadProgress"></el-progress>
          </el-form-item>
          <el-form-item label="个人描述：" class="boldss" prop="serviceDescribe">
            <!-- <el-input type="textarea" placeholder="不能发布虚假信息，介绍必须符合真实情况，不能带有微信号、qq号、手机号等一切联系方式，否则不予通过" v-model="ruleForm.serviceDescribe" maxlength="80" :autosize="{ minRows: 2, maxRows: 4 }" show-word-limit></el-input> -->
            <el-input type="textarea"
                      placeholder="不能发布虚假信息，介绍必须符合真实情况，不能带有微信号、qq号、手机号等一切联系方式，否则不予通过"
                      v-model="ruleForm.serviceDescribe" maxlength="80" :autosize="{ minRows: 2, maxRows: 4 }"
                      show-word-limit></el-input>
          </el-form-item>
        </el-row>
        <!-- 标题主图end -->

        <!-- 效率信息 -->
        <el-row class="cards" id="key3">
          <el-row style="font-weight: bold; font-size: 14px; color: #606266">效率信息：</el-row>
          <el-row :gutter="20" class="cows">
            <el-col :span="8">
              <el-form-item label="根据单位：" class="bolds_padd" prop="efficiencyUnit">
                <el-select v-model="ruleForm.efficiencyUnit" placeholder="请选择">
                  <el-option v-for="(item, index) in serviceEfficiencyUnits" :key="index" :label="item.label"
                             :value="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="参考数量：" class="bolds_padd" prop="efficiencyNumber">
                <!-- <el-input
                  v-model.number="ruleForm.efficiencyNumber"
                  type="number"
                ></el-input>-->

                <el-input placeholder="请输入数字" v-model="efficNum" type="number" class="input-with-select"
                          @change="efficNumChange">
                  <el-select v-model="numUnit" slot="append" placeholder="单位" @change="numUnitChange">
                    <el-option label="个" value="个"></el-option>
                    <el-option label="张" value="张"></el-option>
                    <el-option label="篇" value="篇"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="交付时间：" class="bolds_padd" prop="efficiencyTime">
                <el-input placeholder="请先选单位" v-model="efficiency" type="number" :max="max" :min="0"
                          class="input-with-select" @change="efficiencyChange">
                  <el-select v-model="unit" slot="append" @change="unitChange" placeholder="单位">
                    <el-option label="小时" value="小时"></el-option>
                    <el-option label="天" value="天"></el-option>
                    <el-option label="周" value="周"></el-option>
                    <el-option label="月" value="月"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <!-- 效率信息end -->

        <!-- 收费信息 -->
        <el-row class="cards" id="key4">
          <el-row style="font-weight: bold; font-size: 14px; color: #606266">收费信息：</el-row>
          <el-row :gutter="20" class="cows">
            <el-col :span="8">
              <el-form-item label="服务佣金：" class="bolds_padd" prop="serviceAmountHour">
                <el-input placeholder="请输入佣金" v-model.number="ruleForm.serviceAmountHour" type="number"
                          @blur="inputChange" @keyup.enter.native="inputChange">
                  <template slot="append">元/小时</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-row>
                <el-col :span="11">
                  <el-form-item label="套餐优惠价：" label-width="110px" class="bolds_padd" prop="serviceAmountDay">
                    <el-input v-model.number="ruleForm.serviceAmountDay" type="number"
                              @blur="inputDayChange" @keyup.enter.native="inputDayChange">
                      <template slot="append">元/天</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label-width="0px" style="font-weight: bold; padding: 20px 5px 0"
                                prop="serviceAmountWeek">
                    <el-input v-model.number="ruleForm.serviceAmountWeek" type="number"
                              @blur="inputWeekChange" @keyup.enter.native="inputWeekChange">
                      <template slot="append">元/周</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label-width="0px" class="bolds_padd" prop="serviceAmountMonth">
                    <el-input v-model.number="ruleForm.serviceAmountMonth" type="number"
                              @blur="inputMonthChange" @keyup.enter.native="inputMonthChange">
                      <template slot="append">元/15日</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-row>
        <!-- 收费信息end -->

        <!-- 其他信息 -->
        <el-row class="cards" id="key5">
          <el-row style="font-weight: bold; font-size: 14px; color: #606266">其他信息：</el-row>
          <el-row :gutter="20" class="cows">
            <el-col :span="16">
              <el-form-item label="可雇时段：" class="bolds_padd" prop="startTime">
                <!-- <el-time-picker is-range v-model="ruleForm.serviceTime" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="HH:mm" placeholder="选择时间范围"></el-time-picker> -->
                <el-time-select placeholder="起始时间" v-model="ruleForm.startTime" :picker-options="{
                  start: '00:30',
                  step: '00:30',
                  end: '23:30',
                }"></el-time-select>
                <span style="margin:0 20px;"></span>
                <el-time-select placeholder="结束时间" v-model="ruleForm.endTime" :picker-options="{
                    start: '00:30',
                    step: '00:30',
                    end: '23:30',
                  }"></el-time-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="最短雇期：" class="bolds_padd" prop="shortServiceTimeInt">
                <el-input placeholder="请输入最短雇期" v-model.number="ruleForm.shortServiceTimeInt" type="number">
                  <el-select v-model="unit" slot="append" @change="unitChange" placeholder="单位">
                    <el-option label="小时" value="小时"></el-option>
                    <el-option label="天" value="天"></el-option>
                    <el-option label="周" value="周"></el-option>
                    <el-option label="月" value="月"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="提供人数：" style="font-weight: bold; padding: 0 20px" prop="servicePeople">
                <el-input-number placeholder="请输入人数" v-model.number="ruleForm.servicePeople"
                                 controls-position="right" :min="1" :max="10" style="width: 160px"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <!-- 其他信息end -->

        <!-- 售后服务 -->
        <el-row class="cards" id="key6">
          <el-row style="font-weight: bold; font-size: 14px; color: #606266">售后服务：</el-row>
          <el-row :gutter="20" class="cows">
            <el-col :span="10">
              <!-- <el-form-item label="七天无理由免费修改：" label-width="150px" class="bolds_padd" prop="updateCount"> -->
              <el-form-item label="七天无理由免费修改：" label-width="160px" class="bolds_padd" prop="updateCount">
                <el-input placeholder type="number" v-model.number="ruleForm.updateCount">
                  <template slot="append">次</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开具发票：" class="bolds_padd" prop="invoicing">
                <el-radio-group v-model="ruleForm.invoicing">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-row>
        <!-- 售后服务end -->

        <!-- 服务详情 -->
        <el-row class="cards" id="key6">
          <el-row style="font-weight: bold; font-size: 14px; color: #606266">服务介绍：</el-row>
          <el-row style="margin-top: 20px">
            <editor v-model="ruleForm.serviceContents" :min-height="192" :height="192" />
          </el-row>
          <el-row style="margin-top: 20px; text-align: center">
            <el-button size="medium" type="primary" @click="submitForm('ruleForm')">立即发布</el-button>

            <!-- <el-button size="medium" style="margin-left: 100px" type="warning">保存草稿</el-button> -->
          </el-row>
        </el-row>
        <!-- 服务详情end -->
      </el-form>
      <!-- </div> -->
    </div>
    <!-- 右侧表单end -->
  </div>
  <!-- 中间内容end -->
</template>

<script>
import Editor from "../components/Editor/index.vue";
import draggable from "vuedraggable";
import { getAccessToken } from "@/utils/auth";
import { SERVICE_AMOUNT_TYPE } from "@/constants/service";

export default {
  name: "PublishingService",
  components: { Editor, draggable },
  data() {
    const checkShortServiceTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('最短雇期不能为空'));
      }
      if (isNaN(value) || +value <= 0) {
        callback(new Error('最短雇期必须为大于零的有效数字'));
      } else {
        if (this.efficiency) {
          // 交付时间
          // let dueTime = this.unit === '小时' ? this.efficiency : this.efficiency * 8
          let dueTime = this.efficiency
          if (dueTime > value) {
            callback(new Error('最短雇期不应小于交付时间'));
            return
          }
        }
        callback();
      }
    };
    return {
      // 侧边栏数据
      asideList: [
        {
          icon: "el-icon-warning-outline",
          serviceTitle: "服务信息",
          index: "key2",
        },
        {
          icon: "el-icon-edit",
          serviceTitle: "标题/主图",
          index: "key1",
        },
        {
          icon: "el-icon-document-remove",
          serviceTitle: "效率信息",
          index: "key3",
        },
        {
          icon: "el-icon-data-line",
          serviceTitle: "收费信息",
          index: "key4",
        },
        {
          icon: "el-icon-set-up",
          serviceTitle: "其他信息",
          index: "key5",
        },
        {
          icon: "el-icon-sold-out",
          serviceTitle: "售后服务",
          index: "key6",
        },
      ],
      // 表单数据
      ruleForm: {
        serviceTitle: "", // 标题
        serviceImg: "", // 主图
        serviceVideo: "", //视频
        serviceDescribe: "", // 个人描述
        platformId: "", // 平台
        categoriesId: "", // 服务类目
        itemsId: "", // 服务项目
        serviceAccount:      null, // 是否提供账号
        serviceLabel:        "", // 服务标签
        efficiencyUnit:      "", // 单位
        efficiencyNumber:    "", // 数量
        efficiencyTime:      "", // 交付时间
        serviceAmountHour:   "", // 服务佣金/时
        serviceAmountDay:    "", //天
        serviceAmountWeek:   "", //周
        serviceAmountMonth:  "", //月
        serviceTime:         "", // 可雇时间段
        shortServiceTimeInt: "", // 最短雇期
        shortServiceTimeType: "", // 最短雇期
        servicePeople:       "", // 人数
        updateCount:         1, // 免费修改
        invoicing:           1, // 发票
        serviceContents:     "", // 富文本、
        userId:              "", //用户id
      },
      platform: "", //平台名称
      items: "", //项目名称
      serviceItemParentName: null,
      categories: "", //类目名称
      unit: "小时", // 交付时间、最短雇期 单位，小时、天、周、月
      efficiency: "", // 交付时间
      numUnit: "",
      efficNum: "",

      // 审核状态标题
      title: "",
      // 审核状态内容
      content: "",
      centerDialogVisible: false,
      service_state: null,

      // region 表单规格
      rules: {
        serviceTitle: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 3, max: 50, message: "长度在 3 到 20 个字符", trigger: "blur", },
        ],
        // serviceImg: [
        //   { required: true, message: "请上传主图", trigger: "blur" },
        // ],
        serviceVideo: [
          { required: false, message: "请上传主图", trigger: "blur" },
        ],
        platformId: [
          { required: true, message: "请选择平台", trigger: "change" },
        ],
        categoriesId: [
          { required: true, message: "请选择服务类目", trigger: "change" },
        ],
        itemsId: [
          { required: true, message: "请选择服务项目", trigger: "change" },
        ],
        serviceLabel: [
          { required: true, message: "请选择服务标签", trigger: "blur" },
        ],
        serviceAccount: [
          { required: true, message: "请选择是否提供账号", trigger: "change" },
        ],
        efficiencyUnit: [
          { required: true, message: "请选择单位", trigger: "change" },
        ],
        efficiencyNumber: [
          { required: false, message: "请输入数量后选择单位", trigger: "change", },
        ],
        efficiencyTime: [
          { required: true, message: "请输入时间", trigger: "blur", },
        ],
        serviceAmountHour: [
          { required: true, message: "请输入服务佣金", trigger: "blur" },
        ],
        serviceAmountDay: [
          { required: true, message: "请输入优惠价", trigger: "blur" },
        ],
        serviceAmountWeek: [
          { required: true, message: "请输入优惠价", trigger: "blur" },
        ],
        serviceAmountMonth: [
          { required: true, message: "请输入优惠价", trigger: "blur" },
        ],
        // serviceTime: [
        //   { required: true, message: "请选择雇佣时段", trigger: "change" },
        // ],
        shortServiceTime: [
          { required: true, validator: checkShortServiceTime, trigger: "blur" },
        ],
        servicePeople: [
          { required: true, message: "请输入雇用人数", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "请选择起始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      // endregion

      fixed: false,
      show: false,
      asideIndex: "key2", //默认初始值

      labeList: [
        {
          label: "全部",
          name: "all",
        },
        {
          label: "国内",
          name: "domestic",
        },
        {
          label: "跨境",
          name: "abroad",
        },
      ],
      renderedServicePlatformList: [],
      // 标签
      dark:                         "dark",
      selectedServicePlatformGroup: "all", //默认tab值
      checkTagList:                 [], //选中的标签
      fileType:                     ["png", "jpg", "jpeg", "bmp", "gif"],

      // 上传进度
      progressFlag: false,
      loadProgress: 0,

      //
      ishour: false,
      // disable: true,
      max: null,
      disableForm: false,

      id: null,

      fileList: [],
      type: "",
      uploadlist: [
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
      ],
      UserInfo: "",
    };
  },
  created() {
  },

  mounted() {
    // this.getSelect();
    // 初始化服务平台为全部
    this.renderedServicePlatformList = this.servicePlatformList;
    // 初始化服务项目
    this.serviceItemParentName = '' + this.serviceItemsList[0].itemsName
    // console.log(this.itemsPId)
  },
  computed: {
    stickyHeight() {
      return this.$store.getters.getStickyHeight
    },
    servicePlatformList() {
      return this.$store.getters.getConfig.servicePlatformList
    },
    serviceCategories() {
      return this.$store.getters.getConfig.serviceCategories
    },
    serviceItemsList() {
      return this.$store.getters.getConfig.serviceItemsList
    },
    serviceLabels() {
      return this.$store.getters.getConfig.serviceLabels
    },
    serviceEfficiencyUnits() {
      return this.$store.getters.getConfig.serviceEfficiencyUnits
    },
  },
  methods: {
    getAccessToken,

    // 平台筛选
    clickServicePlatformTab(e) {
      if (e.name === "domestic") {
        this.renderedServicePlatformList = this.servicePlatformList.filter(item => item.platformType === 1);
      } else if (e.name === "abroad") {
        this.renderedServicePlatformList = this.servicePlatformList.filter(item => item.platformType === 2);
      } else {
        this.renderedServicePlatformList = this.servicePlatformList;
      }
    },

    // 交付时间
    efficiencyChange(e) {
      if (this.ishour) {
        if (e > 24) {
          this.efficiency = 24;
        } else if (e < 0) {
          this.efficiency = 0;
        }
      }

      this.ruleForm.efficiencyTime = e + this.unit;
    },
    // 参考数量
    efficNumChange(e) {
      this.ruleForm.efficiencyNumber = e + this.numUnit;
    },
    unitChange(e) {
      if (e === SERVICE_AMOUNT_TYPE.HOUR.label) {
        this.ishour = true;
        this.max = 24;
      } else {
        this.ishour = false;
        this.max = 1000;
      }
      let key = Object.keys(SERVICE_AMOUNT_TYPE).find(key => SERVICE_AMOUNT_TYPE[key].label === e)
      if (key) {
        this.ruleForm.shortServiceTimeType = SERVICE_AMOUNT_TYPE[key].index
      }
      
      if (this.efficiency) {
        this.ruleForm.efficiencyTime = this.efficiency + e;
      }
    },
    numUnitChange(e) {
      if (this.efficNum) {
        this.ruleForm.efficiencyNumber = this.efficNum + e;
      }
    },

    fixedVal(value, num) {
      num = num || 2;
      let tmp = Math.pow(10, num)
      return Math.round(parseFloat(value) * tmp) / tmp
    },
    syncValue(hourValue) {
      if (!this.ruleForm.serviceAmountHour) this.ruleForm.serviceAmountHour = hourValue
      if (!this.ruleForm.serviceAmountDay) this.ruleForm.serviceAmountDay = hourValue * 8;
      if (!this.ruleForm.serviceAmountWeek) this.ruleForm.serviceAmountWeek = hourValue * 8 * 7;
      if (!this.ruleForm.serviceAmountMonth) this.ruleForm.serviceAmountMonth = hourValue * 8 * 15;
    },
    // 套餐优惠价
    inputChange() {
      let hourValue = this.ruleForm.serviceAmountHour
      if (!hourValue) return
      this.syncValue(hourValue)
    },
    inputDayChange() {
      let value = this.ruleForm.serviceAmountDay
      if (!value) return
      let hourValue = this.fixedVal(value / 8)
      this.syncValue(hourValue)
    },
    inputWeekChange() {
      let value = this.ruleForm.serviceAmountWeek
      if (!value) return
      let hourValue = this.fixedVal(value / 8 / 7)
      this.syncValue(hourValue)
    },
    inputMonthChange() {
      let value = this.ruleForm.serviceAmountMonth
      if (!value) return
      let hourValue = this.fixedVal(value / 8 / 15)
      this.syncValue(hourValue)
    },

    // 选择平台标签
    clickServicePlatform(item) {
      this.ruleForm.platformId = item.id;
      this.platform = item.platformName;
    },

    // 选择服务类目标签
    clickServiceCategory(item) {
      this.ruleForm.categoriesId = item.value;
      this.categories = item.label;
    },
    clickServiceItemTab(item) {
      console.log(item)
    },
    // 选择服务类目标签
    clickServiceItem(item, index, parentItem) {
      this.ruleForm.itemsId = item.id;
      this.items = item.itemsName;
      this.show = parentItem.id === 13;
      this.uploadlist[0].img = item.logoImg;
    },
    //服务标签
    handleCheckedCitiesChange(e) {
      if (e.length > 1) {
        this.ruleForm.serviceLabel = e.join(",");
      } else {
        this.ruleForm.serviceLabel = e.join("");
      }
    },

    // 电梯导航
    liClick(key) {
      // ----获取目标元素
      let targetEle = document.querySelector(`#${key}`);
      let height = this.asideIndex == "key1" ? 110 : 110;
      window.scrollTo({
        top: targetEle.offsetTop - height,
        behavior: "smooth",
      });
      this.asideIndex = key;
      // console.log(targetEle.offsetTop, "targetEle");
    },
    uploadVideoSuccess(res) {
      console.log(res)
      if (res.code === 0) {
        this.ruleForm.serviceVideo = res.data;
        this.$message({
          message: "上传成功！",
          type: "success",
        });
      } else {
        this.$message({
          message: "上传失败！",
          type: "error",
        });
      }
    },

    // 超出限制时
    UploadExceed() {
      this.$message({
        message: "最多上传一个视频",
        type: "error",
      });
    },

    // 上传视频格式限制
    beforeUploadVideo(file) {
      // 视频 限制  100 mb
      const isLt50M = file.size / 1024 / 1024 < 100;
      if (
          [
            "video/mp4",
            "video/ogg",
            "video/flv",
            "video/avi",
            "video/wmv",
            "video/rmvb",
          ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt50M) {
        this.$message.error("上传视频大小不能超过100MB哦!");
        return false;
      }
    },

    // 删除视频
    handleRemove() {
      // console.log(file, "file");
      this.ruleForm.serviceVideo = "";
    },

    // 上传表单
    submitForm(formName) {
      this.ruleForm.userId = this.$store.getters.getUser.id;
      // debugger;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 图片处理
          let NumImg = 0;
          let ImgArr = [];
          for (let x = 0; x < this.uploadlist.length; x++) {
            if (this.uploadlist[x].img) {
              NumImg++;
              ImgArr.push(this.uploadlist[x].img);
            }
          }
          if (NumImg == 0) return this.$modal.msgError("请上传主图!");
          this.ruleForm.serviceImg = ImgArr.join(",");
          let Time = [this.ruleForm.startTime, this.ruleForm.endTime];
          this.ruleForm.serviceTime = Time.join("-");
          this.$axios
              .post("/app-api/hiring/push-service/create", this.ruleForm)
              .then((res) => {
                // console.log(res, "res");
                if (res.data.code === 0) {
                  this.$message({
                    message: "发布成功！",
                    type: "success",
                  });
                  setTimeout(() => {
                    // location.reload();
                    this.$router.push({ path: "/UserInfo/serveInfo" });
                  }, 1500);
                }
              })
              .catch((error) => {
                this.$message({
                  message: "发布失败！" + error,
                  type: "error",
                });
              });
          // console.log(this.ruleForm, "ruleForm");
        } else {
          this.$message({
            message: "表单验证失败！请检查填写内容",
            type: "error",
          });
        }
      });
    },

    // 重置表单
    resetForm() {
      this.checkTagList = [];
      this.platform = "";
      this.categories = "";
      this.items = "";
      this.unit = "";
      this.efficiency = "";
      this.numUnit = "";
      this.efficNum = "";
      this.$refs.ruleForm.resetFields();
      this.$message({
        message: "表单已重置！",
        type: "success",
      });
    },
    itemClass(item) {
      return {
        item: true,
        "is-active": this.uploadlist.indexOf(item.img) === this.activeIndex,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.bolds {
  font-weight: bold;
}

/deep/ .boldss .el-form-item__label {
  font-weight: bold;
  margin-top: 8px;
}

.bolds_padd {
  font-weight: bold;
  padding: 20px 20px 0;
}

/deep/ .el-menu {
  border-right: none;
}

/deep/ .el-input-group__append {
  width: 38px !important;
}

/deep/ .el-input__suffix {
  right: 15px !important;
}

.el-header {
  position: sticky;
  top: 88px;
  z-index: 99;
  width: 1440px;

  .head {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    color: #333;
    display: flex;
    height: 60px;
    align-items: center;
  }

  .imgs {
    margin: auto 30px;
    width: 80px;
    height: 45px;
    background-color: #ccc;
  }
}

.aside {
  width: 200px;
  position: sticky;
  display: inline-block;
  top: 20px;
  left: 0;
  align-self: flex-start;
}

// tabs选项卡字体
/deep/ .el-tabs__item {
  font-size: 16px !important;
}

.contenter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // 表单栏样式
  .mains {
    width: 1220px;

    /deep/ .el-alert__title {
      font-size: 18px;
    }

    /deep/ .el-alert__icon {
      font-size: 18px;
    }

    .cards {
      border: 1px solid #e1e1e1;
      padding: 20px;
      background-color: #fff;
      border-radius: 5px;

      & + .cards {
        margin-top: 15px;
      }

      /deep/ .el-input-group__append {
        padding: 0 5px !important;
        text-align: center;
      }
    }

    // 主图上次框大小修改
    .picuplod {
      .heihei {
        display: flex;

        .item {
          margin-right: 20px;
        }
      }

      /deep/ .el-upload-dragger {
        width: 120px !important;
        height: 120px !important;
      }

      /deep/ .el-upload-list__item,
      /deep/ .el-upload--picture-card {
        width: 120px !important;
        height: 120px !important;
        line-height: 118px !important;
      }

      /deep/ .el-icon-plus {
        font-size: 20px !important;
      }

      /deep/ .el-upload-list__item-status-label {
        display: none !important;
      }
    }

    .upload-demo {
      /deep/ .el-upload-dragger {
        width: 220px !important;
        height: 120px !important;
      }

      /deep/ .el-icon-upload {
        line-height: 0px !important;
      }
    }

    /deep/ .el-popover {
      box-shadow: 0 0 4px 2px #e1e1e1 !important;
    }

    .cows {
      /deep/ .el-form-item__label {
        font-size: 14px !important;
      }
    }
  }

  /deep/ .el-textarea__inner::-webkit-input-placeholder {
    padding-top: 12px;
  }
}
</style>
